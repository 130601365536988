<template>
  <v-dialog v-model="showDialog" :fullscreen="$vuetify.breakpoint.mobile" width="400">
    <v-sheet class="pa-6 pa-md-10" color="white">
      <div class="d-flex gap-10">
          <h2 class="mb-5">Enter a new password</h2>
      </div>
      <div>
        <v-text-field
            v-model="password"
            label="New Password"
            outlined
            dense
            :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (value = !value)"
            :type="value ? 'password' : 'text'"
        />
        <div class="text-caption red--text mt-n7" v-show="$v.password.$dirty && $v.password.$invalid && !$v.password.required">Field is required</div>
        <div class="text-caption red--text mt-n7" v-show="$v.password.$dirty && $v.password.$invalid && !$v.password.maxLength">Maximum password length is 255</div>
        <div class="text-caption red--text mt-n7" v-show="$v.password.$dirty && $v.password.$invalid && !$v.password.minLength">Minimum password length is 6</div>
      </div>
      <div class="d-flex">
        <v-btn class="mt-5 mx-auto" @click="cancel">Cancel</v-btn>
        <v-btn class="mt-5 mx-auto" color="primary" @click="submitNewPassword">Submit</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import {EventBus} from "@/bootstrap/EventBus";
import {maxLength, required, minLength} from "vuelidate/lib/validators";
import usersApi from "@/api/modules/users"

export default {
  name: "ResetPasswordPopup",
  data() {
    return {
      showDialog: false,
      user: null,
      password: null,
      value: 'password'
    }
  },
  validations: {
    password: {
      required,
      maxLength: maxLength(255),
      minLength: minLength(6)
    }
  },
  created() {
    EventBus.$on('reset-password-popup', user => {
      this.user = user
      this.showDialog = true
    })
  },
  methods: {
    submitNewPassword() {
      this.$v.$touch()
      if(this.$v.$invalid) return
      usersApi.updateUserPassword(this.user.id, { password: this.password })
          .then(() => {
            this.showDialog = false
            this.$notify({
              title: 'Success',
              text: 'Password Changed',
              type: 'success'
            });
          })
          .catch(() => {
            this.showDialog = false
            this.$notify({
              title: 'Error',
              text: 'Occurred some error',
              type: 'error'
            });
          })
          .finally(() => {
            this.cancel()
          })
    },
    cancel() {
      this.$v.$reset()
      this.user = null
      this.password = null
      this.value = 'password'
      this.showDialog = false
    }
  }
}
</script>